'use strict';

(function($) {
  var app = {

    width: 768,

    init: function() {

      var body = $('body'),
          searchBtn = $('.js-search'),
          searchWrap = $('#search-wrap'),
          searchClose = $('#js-search-close'),
          searchInput = $('#form_buscar input'),
          newsClose = $('#newsletter-bar__close'),
          newsBar = $('.newsletter-bar'),
          btnCatalogo = $('#btn-catalogo'),
          sentCatalogo = $('.warning-message-sent')
          formCatalogo = $('.refill-form'),
          $nav = $('.c-nav'),
          stockValidation = $('.stock-validation'),
          $sliderHome = $('.slider-home-container'),
          navCallerMovables = $('.c-nav-span[data-move="true"]');

      $('select:not(.unmodify)').material_select();
      document.querySelectorAll('.select-wrapper').forEach(function (t) {
        return t.addEventListener('click', function (e) {
          return e.stopPropagation();
        });
      });

      searchBtn.on('click', function(event) {
        event.preventDefault()
        body.addClass('active')
        searchWrap.addClass('active')
        setTimeout(function(){
          searchInput.focus()
        }, 300);
      })

      searchClose.on('click', function(event) {
        event.preventDefault()
        body.removeClass('active')
        searchWrap.removeClass('active')
        searchInput.blur()
      })

      newsClose.on('click', function(event) {
        event.preventDefault()
        newsBar.removeClass('show')
        setTimeout(function(){
          $.ajax(
              {
                url : site_url + '/close_new_bar',
                success : function ()
                {
                  newsBar.addClass('hide')
                }
              }
          )
        }, 300);
      })

      btnCatalogo.on('click', function(event) {
        sentCatalogo.addClass('hide')
        formCatalogo.removeClass('hide')
      })

      $('.scrolleffect').on('click', function(event) {
        event.preventDefault();
        app.smoothScroll(this);
      });

      // catalogo-imagenes
      $('.filter-btn').on('click', function(event) {
        body.toggleClass('filters-open');
      });

      $('.filters-bg').on('click', function(event) {
        body.removeClass('filters-open');
      });

      $('.btn-filter-apply').on('click', function(event) {
        body.removeClass('filters-open');
      });

      // Search button /colecciones
      $('.btn-search-colleciones').on('click', function(event) {
        body.addClass('colecciones-search');
      });
      $('.btn-search-colleciones-close').on('click', function(event) {
        body.removeClass('colecciones-search')
      });

      $(document).keyup(function(e) {
        if ( body.hasClass( "colecciones-search" ) ) {
          if (e.key === "Escape") {
            body.removeClass('colecciones-search')
          }
        }
      });

      $(window).scroll(function(event) {

        let positionToChange = 144;
        if(typeof $sliderHome.html() !== 'undefined') {
          positionToChange = ($sliderHome.outerHeight() - $nav.outerHeight())/2;
        }

        if ($(window).scrollTop() > positionToChange){
          $nav.addClass('scrolled');
          if(typeof $sliderHome.html() !== 'undefined') $sliderHome.addClass('scrolled');
        } else {
          $nav.removeClass('scrolled');
          if(typeof $sliderHome.html() !== 'undefined') $sliderHome.removeClass('scrolled');
        }

      });

      $('.lazyYT').lazyYT();
      objectFitImages();
      app.colecciones();
      app.magnific();
      app.youplay();
      app.scrollReveal();
      app.bannerPopup();
      app.slickSlider();
      app.sliderInternalizacion();
      app.formValidator();
      app.solutionsMoreProjects();
      app.notClickableLinks();
      app.arquitectsNavigation();
      this.selectConocer();
      app.navigationPositions();
      this.filterCollapsible();
      this.filterBreadcrumbs();
      this.mosaicBlocks();
      this.packaging();
      this.formBudget();
      this.menuMosaicMobile();
      this.menuInstalationsMobile();
      this.materialsTables();
      this.generatorIntroMobile();
      this.cookiesAccept();
      this.cookiesRefuse();
      this.cookiesConfigure();
      this.cookiesConfigureLegal();
      this.cookiesAcceptVideos();
      this.rangeDoubleSlide();
      return false;

    },
    ajaxCall: function(url, type, dataType, $dataObj, cache, crossDomain) {
      // Default values if undefined values comes
      if (typeof type === 'undefined') type = 'GET';
      if (typeof dataType === 'undefined') {
        /**
         "xml": Returns a XML document that can be processed via jQuery.
         "html": Returns HTML as plain text; included script tags are evaluated when inserted in the DOM.
         "script": Evaluates the response as JavaScript and returns it as plain text. Disables caching by appending a query string parameter, _=[TIMESTAMP], to the URL unless the cache option is set to true. Note: This will turn POSTs into GETs for remote-domain requests.
         "json": Evaluates the response as JSON and returns a JavaScript object. Cross-domain "json" requests are converted to "jsonp" unless the request includes jsonp: false in its request options. The JSON data is parsed in a strict manner; any malformed JSON is rejected and a parse error is thrown. As of jQuery 1.9, an empty response is also rejected; the server should return a response of null or {} instead. (See json.org for more information on proper JSON formatting.)
         "jsonp": Loads in a JSON block using JSONP. Adds an extra "?callback=?" to the end of your URL to specify the callback. Disables caching by appending a query string parameter, "_=[TIMESTAMP]", to the URL unless the cache option is set to true.
         "text": A plain text string.
         */
        dataType = 'html';
      }
      if (typeof dataToSend === 'undefined') dataToSend = {};
      if (typeof crossDomain === 'undefined') crossDomain = false;

      /* 99% percent times, is a form. By default serialize */
      if (typeof $dataObj === 'object') {
        dataToSend = $dataObj.serialize();
      }

      $.ajax({
        type: 'POST',
        url: "/",
        data: { data: dataToSend },
        dataType: "html",
        cache: false,
        async: true,
        crossDomain: crossDomain,
        timeout: 5,
        xhr: function() {
          var xhr = new window.XMLHttpRequest();
          //Upload progress
          xhr.upload.addEventListener("progress", function(evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              //Do something with upload progress
            }
          }, false);
          //Download progress
          xhr.addEventListener("progress", function(evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              //Do something with download progress
            }
          }, false);

          return xhr;
        },
        beforeSend: function (xhr, settings) {
          // code here
        },
        complete: function (xhr, status) {
          // code here
        },
        success: function (response) {
          // code here
        },
        error : function (xhr, textStatus, errorThrown) {
          // code here
        },
      });
      return true;
    },
    cleanUri: function(str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
      return str;
    },
    magnific: function() {

      $('.popup-gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
          delegate: 'a',
          type: 'image',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          }
        });
      });

      $('.popup-gallery2').each(function() {
        $(this).magnificPopup({
          delegate: 'a',
          type: 'image',
          mainClass: 'mfp-img-mobile',
          callbacks: {
            elementParse: function(item) {
              var self = $(item.el.context),
                  selfType = self.data("type");
              console.log(selfType);
              //console.log(item.el.context.className);
              //console.log(selfType);
              //console.log( self.data("type") );
              if(selfType == 'video') {
                item.type = 'iframe',
                    item.iframe = {
                      patterns: {
                        youtube: {
                          index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                          id: 'v=', // String that splits URL in a two parts, second part should be %id%
                          // Or null - full URL will be returned
                          // Or a function that should return %id%, for example:
                          // id: function(url) { return 'parsed id'; }

                          src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
                        },
                        vimeo: {
                          index: 'vimeo.com/',
                          id: '/',
                          src: '//player.vimeo.com/video/%id%?autoplay=1'
                        },
                        gmaps: {
                          index: '//maps.google.',
                          src: '%id%&output=embed'
                        }
                      }
                    }
              }else if(selfType === 'video-no-cookie') {
                window.location = self.attr('href');
              } else {
                item.type = 'image',
                    item.tLoading = 'Loading image #%curr%...',
                    item.mainClass = 'mfp-img-mobile',
                    item.image = {
                      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
                    }
              }
            }
          },
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          }
        })
      });

      $('.mfp').each(function() {
        $(this).magnificPopup({
          delegate: 'a',
          type: 'image',
          mainClass: 'mfp-img-mobile mfp-ezarri',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            markup: '<div class="mfp-figure">'+
                '<div class="mfp-close"></div>'+
                '<div class="mfp-img"></div>'+
                '<div class="mfp-bottom-bar">'+
                '<div class="mfp-title mfp-data"></div>'+
                '<div class="mfp-counter"></div>'+
                '</div>'+
                '</div>',
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
              return '<div class="mfp-first">'+
                  item.el.parent().parent().prev().find('.mfp-name').text()+
                  ' &ndash; '+
                  item.el.parent().parent().prev().find('.mfp-location').text()+
                  '</div>'+
                  '<div>'+
                  item.el.parent().parent().prev().find('.mfp-description').html()+
                  '</div>';
            },
            verticalFit: true
          }
        });

        var self = $(this);
        self.find('figure').on('click', function(){
          self.magnificPopup('open');
        });
      });

    },
    youplay: function() {
      // Play YouTube vídeos
      var youBtn = $('.video-btn');

      youBtn.on('click', function(event) {
        var btn = $(this);
        var videoC = btn.closest('.video-container');
        videoC.addClass('active');
        videoC.find('iframe')[0].src += "&autoplay=1";
      });
    },
    smoothScroll: function($obj) {
      var target = $($obj.hash);
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - 50
        }, 1000);
      }
    },
    bannerPopup: function() {
      $(window).scroll(function(){
        if($(document).scrollTop()>=$(document).height()/5)
          $("#bannerpopup").show("slow");else $("#bannerpopup").hide("slow");
      });
      $('#close').click(function(){
        $("#bannerpopup").addClass("hide");
        if( $("#bannerpopup").hasClass('webinarpopup'))
        {
          $.ajax(
              {
                url : site_url + '/banner_webinars_close',
              }
          )
        }
        if( $("#bannerpopup").hasClass('coleccionespopup'))
        {
          $.ajax(
              {
                url : site_url + '/banner_colecciones_close',
              }
          )
        }
      });
      $('.btn-banner').click(function(){
        $("#bannerpopup").addClass("hide");
        if( $("#bannerpopup").hasClass('webinarpopup'))
        {
          $.ajax(
              {
                url : site_url + '/banner_webinars_close',
              }
          )
        }
        if( $("#bannerpopup").hasClass('coleccionespopup'))
        {
          $.ajax(
              {
                url : site_url + '/banner_colecciones_close',
              }
          )
        }
      });
    },
    slickSlider: function() {
      var slickEl = $('.slick-slider');

      // Slick Manual
      var $slickManual = $('.slick-manual');
      var $slickPics = $('.slick-manual-pics');
      var $slideNum = $('.manual-slide');
      var $slickStatusTotal = $('.manual-total');
      var $slickManualBtnPrevious = $('.manual-previous');
      var $slickManualBtnNext = $('.manual-next');
      var $slickManualBtnReplay = $('.manual-replay');

      //Slick side to side
      var slickSideToSide = $('.slick-slider-side');

      $slickManual.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;

        $slideNum.text(i);
        $slickStatusTotal.text(slick.slideCount);

        $slickManualBtnReplay.on('click', function() {
          $(this).removeClass('active');
          $slickManual.slick('slickGoTo', 0, true);
        });

        $slickManualBtnPrevious.on('click', function() {
          $slickManual.slick('slickPrev');
          $slickManualBtnReplay.addClass('active');
        });

        $slickManualBtnNext.on('click', function() {
          $slickManual.slick('slickNext');
          $slickManualBtnReplay.addClass('active');
        });

        if(i == 1) $slickManualBtnPrevious.prop('disabled',true);
        else $slickManualBtnPrevious.prop('disabled',false);

        if(i == slick.slideCount) $slickManualBtnNext.prop('disabled',true);
        else $slickManualBtnNext.prop('disabled',false);

      });

      slickEl.slick();
      slickSideToSide.slick(
          {
            centerMode: true,
            centerPadding: '16vw',
            slidesToShow: 1,
            dots: true
          }
      );

      var Tabs = function(options) {
        var elem = document.getElementById(options.elem),
            open = options.open || 0,
            titleClass = 'js-tabs__title',
            activeClass = 'active',
            contentClass = 'js-tabs__content',
            tabsNum = elem.querySelectorAll('.' + titleClass).length;
        render();

        /**
         * Initial rendering of the tabs.
         */
        function render(n) {
          elem.addEventListener('click', onClick);

          var init = (n == null) ? checkTab(open) : checkTab(n);

          for (var i = 0; i < tabsNum; i++) {
            elem.querySelectorAll('.' + titleClass)[i].setAttribute('data-index', i);
            if (i === init) openTab(i);
          }
        }

        /**
         * Handle clicks on the tabs.
         *
         * @param {object} e - Element the click occured on.
         */
        function onClick(e) {
          if (e.target.className.indexOf(titleClass) === -1) return;
          e.preventDefault();
          openTab(e.target.getAttribute('data-index'));
        }

        /**
         * Hide all tabs and re-set tab titles.
         */
        function reset() {
          [].forEach.call(elem.querySelectorAll('.' + contentClass), function(item) {
            item.style.display = 'none';
          });

          [].forEach.call(elem.querySelectorAll('.' + titleClass), function(item) {
            item.className = removeClass(item.className, activeClass);
          });
        }

        /**
         * Utility function to remove the open class from tab titles.
         *
         * @param {string} str - Current class.
         * @param {string} cls - The class to remove.
         */
        function removeClass(str, cls) {
          var reg = new RegExp('(\ )' + cls + '(\)', 'g');
          return str.replace(reg, '');
        }

        /**
         * Utility function to remove the open class from tab titles.
         *
         * @param n - Tab to open.
         */
        function checkTab(n) {
          return (n < 0 || isNaN(n) || n > tabsNum) ? 0 : n;
        }

        /**
         * Opens a tab by index.
         *
         * @param {number} n - Index of tab to open. Starts at 0.
         *
         * @public
         */
        function openTab(n) {
          reset();

          var i = checkTab(n);

          elem.querySelectorAll('.' + titleClass)[i].className += ' ' + activeClass;
          elem.querySelectorAll('.' + contentClass)[i].style.display = '';

          // Testing
          var el = $(elem.querySelectorAll('.' + contentClass)[i]);
          var slider = el.find('.slick-slider');

          slider.slick('setPosition');
          slider.slick('setPosition');
          slider.slick('setPosition');
        }

        /**
         * Updates the tabs.
         *
         * @param {number} n - Index of tab to open. Starts at 0.
         *
         * @public
         */
        function update(n) {
          destroy();
          reset();
          render(n);
        }

        /**
         * Removes the listeners from the tabs.
         *
         * @public
         */
        function destroy() {
          elem.removeEventListener('click', onClick);
        }

        return {
          open: openTab,
          update: update,
          destroy: destroy
        };
      };

      // Comprobamos si existe el elemento
      if ( $( "#tabs" ).length ) {
        var tabs = new Tabs({
          elem: 'tabs'
        });
      }
    },
    colecciones: function() {
      var $button = $('.btncol'),
          $lista = $('.lista'),
          selcol=0;

      $button.click( function(event) {
        event.preventDefault();
        event.stopPropagation();
        var cual = $(this).attr('data-target');

        $button.removeClass('active');
        $lista.hide();
        if (selcol!=cual) {
          $(this).addClass('active');
          $(this).next($lista).show();
          selcol=cual;
        } else {
          selcol = 0;
        }

      });

      function colHide() {
        $button.removeClass('active');
        $lista.hide();
        selcol = 0;
      }
      window.onclick = function() {
        colHide();
      };
    },
    scrollReveal: function() {
      window.sr = ScrollReveal();

      // General
      sr.reveal('.sr-item', { duration: 2000, scale: 0, distance: '50%' });
      sr.reveal('.sr-h-col', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-h-col2', { duration: 2000, scale: 0, distance: '50%' }, 50);
      //sr.reveal('.sr-soluciones-i', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-h-blog-h', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-h-blog', { duration: 2000, scale: 0, distance: '50%' }, 50);

      // Área técnica - JointPoint
      sr.reveal('.sr-jp-01', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-02', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-03', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-04', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-05', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-06', { duration: 2000, scale: 0, distance: '50%' }, 50);
      sr.reveal('.sr-jp-07', { duration: 2000, scale: 0, distance: '50%' }, 50);

      // Área técnica - JointPoint / Bloques
      sr.reveal('.sr-default', { duration: 2000, scale: 0, distance: '50%' });
      sr.reveal('.sr-filter-img', { duration: 750, scale: 0, distance: '0' }, 25);

    },
    sliderInternalizacion: function() {
      $('.slider-internalizacion').slick({
        autoplay: true,
        autoplaySpeed:500,
        slidesToShow:1,
        pauseOnHover:false,
        dots:false,
        fade:true,
        draggable:false,
        arrows: false,
      });
    },
    formValidator: function() {

      $('.fake-validation').blur(function() {

        if ( $(this).val().length === 0 ) {
          $(this).removeClass('fake-valid');
        } else {
          $(this).addClass('fake-valid');
        }

      });

      var btn = $('.btn-fake');

      btn.click( function(event) {

        $('.fake-validation').each(function() {
          if ( $(this).val().length === 0 ) {
            $(this).removeClass('fake-valid');
          } else {
            $(this).addClass('fake-valid');
          }
        });
      })
    },
    solutionsMoreProjects : function ()
    {
      var projectContainer = $('.projects-container');
      var buttonMoreContainer = $('.more-projects-button-container');
      var buttonMore = buttonMoreContainer.find('.ver-mas').find('a');

      buttonMore.on('click',function (e)
      {
        e.preventDefault();
        projectContainer.children('div').each(function ()
        {
          if(!$(this).hasClass('loader-container')) $(this).remove();
        })
        projectContainer.addClass('loading');
        $.ajax(
            {
              url : site_url + '/all_projects',
              dataType : 'json',
              method : 'POST',
              data : {solution : buttonMore.data('solution')},
              success : function(data)
              {
                if(!data.hasError)
                {
                  var html = '';
                  for(var d in data)
                  {
                    html +=
                        '<div class="col s12 m6 l4 xl3">' +
                        ' <div class="item-c cs-c mfp">' +
                        '   <figure>' +
                        '     <div class="item-c_hover">' +
                        '       <div class="item_c_lines">' +
                        '           <div class="item_c_line-t"></div>' +
                        '         <div class="item_c_line-r"></div>' +
                        '         <div class="item_c_line-b"></div>' +
                        '         <div class="item_c_line-l"></div>' +
                        '       </div>' +
                        '     </div>' +
                        '     <img src="' + data[d]['imagen'] + '" alt="' + data[d]['alt_l'] + '" class="responsive-img w-100">' +
                        '   </figure>' +
                        '   <div class="cs-c_content">' +
                        '     <h2 class="cs-c_title">' + data[d]['nombre_l'] + '</h2>' +
                        '     <span class="cs-c_country">' + data[d]['localizacion_l'] + '</span>' +
                        '   </div>';
                    if(typeof data[d]['galeria'] !== 'undefined' && Array.isArray(data[d]['galeria']))
                    {
                      html +=
                          '   <div class="mfp-data" style="display:none">' +
                          '     <span class="mfp-name">' + ((data[d]['nombre_l']) ? data[d]['nombre_l'] : '') + '</span>' +
                          '     <span class="mfp-location">' + ((data[d]['localizacion_l']) ? data[d]['localizacion_l'] : '') + '</span>' +
                          '     <span class="mfp-description">' + ((data[d]['descripcion_l']) ? data[d]['descripcion_l'] : '') + '</span>' +
                          '   </div>' +
                          '   <ul>';
                      for(var g in data[d]['galeria'])
                      {
                        html +=
                            '     <li><a href="' + data[d]['galeria'][g]['imagen'] + '" title="' + data[d]['galeria'][g]['alt_l'] + '">' + data[d]['galeria'][g]['alt_l'] + '</a></li></li>';
                      }
                      html +=
                          '   </ul>';
                    }
                    html +=
                        ' </div>' +
                        '</div>';
                  }
                  projectContainer.find('.loader-container').after(html);
                  app.magnific(); //Recargamos galeria
                  buttonMoreContainer.remove(); //Quitamos el boton
                }
                projectContainer.removeClass('loading');
              }
            }
        )
      })

    },
    notClickableLinks: function()
    {
      $('.not-clickable-link').click(function (e){
        e.preventDefault();
      })
    },
    arquitectsNavigation: function ()
    {
      $('.descargas__list').find('.navigable').click(function(){
        var url = $(this).data('url');
        var type = $(this).data('type');
        var file = $(this).data('file');

        if(type === 'dir')
        {
          //Accedemos al directorio
          ga('send', 'event', 'Arquitectos e Interioristas', 'Acceso Directorio', file, {
            hitCallback : function(){
              window.location.href = url;
            }
          });
        }
        else
        {
          //Descarga de fichero
          ga('send', 'event', 'Arquitectos e Interioristas', 'Descarga Fichero', file, {
            hitCallback : function(){
              window.open(url);
            }
          });
        }

      })

      $('.breadcrumb-file-navigation').find('a').click(function (e)
      {
        e.preventDefault();
        var obj = $(this);
        ga('send', 'event', 'Arquitectos e Interioristas', 'Acceso Directorio', obj.attr('title'), {
          hitCallback : function(){
            window.location.href = obj.attr('href');
          }
        });
      })
    },
    selectConocer : function () {
      var select = $('select#conocido');
      var inputContainer = $('.input-formularios__conocido_otros');
      if(typeof select.html() !== 'undefined' && typeof inputContainer.html() !== 'undefined')
      {
        if(parseInt(select.val()) === 6)
        {
          inputContainer.show();
        }
        else
        {
          inputContainer.hide();
        }
        select.change(function () {
          if(parseInt($(this).val()) === 6)
          {
            inputContainer.show();
          }
          else
          {
            inputContainer.hide();
          }
        })
      }
    },
    navigationPositions : function () {
      this.navMovablesPosition();
      $(window).resize(function (){ app.navMovablesPosition(); })
    },
    navMovablesPosition : function () {
      navCallerMovables.each(function () {
        var navigation = $(this).parent().children('.c-nav_submenu'),
            elements = navigation.find('.c-nav_submenu_list'),
            windowsWidth = $(window).width(),
            start = $(this).position().left;
        if($(this).data('start')) {
          //Colocamos la primera ul al borde izquierdo del elemento que lo llama
          navigation.css({justifyContent : 'start'});
          elements.first().css({marginLeft: 'calc(-6rem + ' + start + 'px)'});
        }
        else if($(this).data('end')) {
          //Colocamos la ultima ul al borde izquierdo del elemento que lo llama
          var elementLastPosition = elements.last().position().left;

          elements.last().css({marginRight: 'calc(6rem + ' + (elementLastPosition - start) + 'px)'});
        }

      })
    },
    filterCollapsible : function () {
      let filterItems = $('.filters-item');
      filterItems.each(function (){
        let obj = $(this);
        let caller = obj.find('h2');
        let closeCaller = obj.find('.close');
        caller.on('click',function (){
          if(!obj.hasClass('showing')) {
            obj.addClass('showing');
          } else {
            obj.removeClass('showing');
          }
        })
        closeCaller.click(function (){
          obj.removeClass('showing');
        })
      })
    },
    filterBreadcrumbs : function () {
      let filterItems = $('.filters-item');
      if(typeof filterItems.html() !== 'undefined') {
        this.getFiltersBreadcrumbs();
        filterItems.find('label').find('input:not(.not-auto)').click(function (event){
          event.stopPropagation();
          app.getFiltersBreadcrumbs();
          if($(window).width() < 601) {
            //En versiones moviles colapsamos menu desplegable
            filterItems.removeClass('showing');
          }
        })
      }
    },
    getFiltersBreadcrumbs : function () {
      let filterInputs = $('.filters-item').find('input');
      let checked = {};
      let breadContainer = $('.filters-bread');
      let breadBase = breadContainer.find('.base');

      if(typeof breadContainer.html() === 'undefined') return;

      filterInputs.each(function(){
        if($(this).prop('checked')) {
         if($(this).attr('name').indexOf('_colorAgua-') !== -1)
         {
           checked[$(this).attr('name')] = globalLang['filtro-coloragua'] + ': ' + $(this).parent().children('.option').find('img').attr('alt');
         }
         else if ($(this).attr('name') == 'mis_likes')
         {
           checked[$(this).attr('name')] = 'Likes';
         }
         else
         {
           checked[$(this).attr('name')] = $(this).parent().children('.option').html();
         }
        }
      })

      if(Object.keys('checked').length > 0) {
        this.clearFilterBread();
        for (let item in checked) {
          let newItem = breadBase.clone();
          newItem.html(newItem.html().replace('__NOMBRE_FILTRO__',checked[item]));
          newItem.html(newItem.html().replace('__FILTRO__',item));
          breadContainer.append('<span class="item">' + newItem.html() +  '</span>');
        }
        breadContainer.find('[data-filtro]').off('click').on('click',function (){
          app.deleteFilterBread($(this));
        })
        breadContainer.show();
      } else {
        this.clearFilterBread();
        breadContainer.hide();
      }

      this.getMosaics();

    },
    clearFilterBread : function () {
      let breadContainer = $('.filters-bread');
      breadContainer.find('.item:not(.base)').remove();
    },
    deleteFilterBread : function (caller) {
      let breadContainer = $('.filters-bread');
      $('input[name="' + caller.data('filtro') + '"]').prop('checked',false);
      caller.parent().remove();
      if(breadContainer.find('.item:not(.base)').length <= 0) breadContainer.hide();
      this.getMosaics();
    },
    getMosaics : function() {
      let results = $('.filter-result'),
          resultsList = results.find('ul:not(.pagination)'),
          loading = results.find('.loading'),
          pagination = results.find('.pagination'),
          isMosaicList = resultsList.hasClass('mosaic-list'),
          form = $('#form'),
          noResults = $('.no-results'),
          url = window.location.host + window.location.pathname,
          formData = form.serialize();
      resultsList.empty();
      pagination.empty();
      noResults.remove();
      loading.show();
      $.ajax({
        url : site_url + '/get_' + ((isMosaicList) ? 'mosaicos' : 'galeria'),
        dataType : 'json',
        data : formData,
        method: 'post',
        success : function (data) {
          if(!data.regs) {
            results.append('<p class="fw-500 no-results">' + globalLang['catalogo__no_resultados'] + '</p>');
          } else {
            if(isMosaicList) app.listMosaics(data,resultsList);
            else app.listGallery(data,resultsList);
            app.collectionPagination(data,pagination,form,resultsList);
          }
          let urlGet = app.getUrlGet(formData,(isMosaicList),false);
          let urlGetForChange = app.getUrlGet(formData,(isMosaicList),true);
          let newUrl = window.location.protocol + "//" + url + urlGet;
          window.history.pushState({path : newUrl},'',newUrl);
          let linkTarget = $('#nav-gallery').find('li').find('a');
          let linkTargetHref = linkTarget.attr('href');
          if(typeof linkTargetHref !== 'undefined' && linkTargetHref.indexOf('?') !== -1) {
            linkTargetHref = linkTargetHref.split('?')[0];
          }
          linkTarget.attr('href',linkTargetHref + urlGetForChange);
          loading.hide();
          if(typeof resultsList !== 'undefined') {
            let resultsListOffset = resultsList.offset();
            if(typeof resultsListOffset !== 'undefined') {
              $([document.documentElement, document.body]).animate({
                scrollTop: resultsListOffset.top - 200
              }, 500);
            }
          }
        }
      })
    },
    listMosaics : function (data,resultsList) {
      for (let d in data.regs) {
        console.log(data.regs[d].nuevo);
       let html =`<li>
                    <a href="${(typeof data.regs[d].finalUrl !== 'undefined' && data.regs[d].finalUrl !== '' && data.regs[d].finalUrl !== null) ? data.regs[d].finalUrl : site_url + '/' + data.regs[d].seo_url_l}" class="item-c">
                        <figure>
                            ${(data.regs[d].nuevo && parseInt(data.regs[d].nuevo) === 1) ? '<span class="new">NEW</span>' : ''}
                            <div class="item-c_hover">
                                <div class="item_c_lines">
                                    <div class="item_c_line-t"></div>
                                    <div class="item_c_line-r"></div>
                                    <div class="item_c_line-b"></div>
                                    <div class="item_c_line-l"></div>
                                </div>
                            </div>
                            <div class="img-square mosaic-list__img">
                                <img src="${base_url + '/media/uploads/mosaicos/ficha_/' + ((typeof data.regs[d].finalImg !== 'undefined' && data.regs[d].finalImg) ? data.regs[d].finalImg : data.regs[d].imagen)}" alt="${data.regs[d].alt_l}" />
                            </div>
                        </figure>
                        <h2>${(typeof data.regs[d].finalName !== 'undefined' && data.regs[d].finalName !== '' && data.regs[d].finalName !== null) ? data.regs[d].finalName : data.regs[d].nombre_l}</h2>
                    </a>`
                    /*<div class="like">
                        <div id="click" class="d-flex align-items-center">
                            <div class="heart mosaico${(typeof data.likes !== 'undefined' && $.inArray(data.regs[d].pid,data.likes) !== -1) ? ' yes' : ''}" data-id="${data.regs[d].pid}"></div>
                        </div>
                    </div> */
        html += `
                </li>`;
        //Añadimos
        resultsList.append(html);
        //Activamos heart
        $(`.heart[data-id="${data.regs[d].pid}"]`).off('click').on('click',function (){
          let heartCaller = $(this);
          app.likeMosaicGallery(heartCaller);
        })
      }
    },
    listGallery : function (data,resultsList) {
      let image = null;
      let html = null;
      for (let i = 0; i <= 3; i++) {
        for (let j = 0; j <= 3; j++) {
          if(typeof data.regs[i+4*j] === 'undefined') continue;
          image = data.regs[i+4*j];

          html = `<li class="sr-filter-img">
                    <a title="${image.alt_l}" data-url="${site_url + '/' + image.seo_url_l}" ${(typeof image.video !== 'undefined' && image.video !== '' && image.video !== null) ? `href="https://www.youtube.com/watch?v=${image.video}" xdata-url="https://google.es" class="item-c popup-youtube"` : `href="${base_url + 'media/uploads/mosaicos/galeria/general_/' + image.imagen}" class="item-c mfp-coleccion-img"`}>
                        <figure>
                            <div class="item-c_hover">
                                <div class="item_c_lines">
                                    <div class="item_c_line-t"></div>
                                    <div class="item_c_line-r"></div>
                                    <div class="item_c_line-b"></div>
                                    <div class="item_c_line-l"></div>
                                </div>
                            </div>
                            <div class="filter-img">
                                <img src="${base_url + 'media/uploads/mosaicos/galeria/galeria_/' + image.imagen}" alt="">
                            </div>
                        </figure>
                    </a>`
                    /*
                    <div class="like">
                        <div id="click" class="d-flex align-items-center">
                            <div class="heart${(typeof data.votos !== 'undefined' && $.inArray(image.id,data.votos) !== -1) ? ' yes' : ''}" data-id="${image.id}"></div>
                        </div>
                    </div> */
              html += `
                  </li>`;
          //Añadimos
          resultsList.append(html);
          //Activamos heart
          $(`.heart[data-id="${image.id}"]`).off('click').on('click',function (){
            let heartCaller = $(this);
            app.likeMosaicGallery(heartCaller);
          })
        }
      }
      //Activamos los popup
      $('.mfp-coleccion-img').magnificPopup({
        type: 'image',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1]
        },
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile mfp-coleccion',
        image: {
          verticalFit: true,
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
            return '<span class="mfp-title-sp">' + item.el[0].getAttribute("title") + '</span><a href="' + item.el.attr('data-url') + '" class="btn-reset btn-gen text-u fw-700 waves-effect waves-light">' + globalLang['ver-mosaico-utilizado'] + '</a>';
          }
        }
      });


      $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-img-mobile mfp-coleccion',
        removalDelay: 160,
        preloader: false,
        callbacks: {
          open:	function() { $('#mp_boton').attr('href',this.currItem.el.attr('data-url'));	},
        },
        iframe: {
          markup: '<div class="mfp-iframe-scaler">'+
              '<div class="mfp-close"></div>'+
              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
              '</div><a id="mp_boton" href="" class="btn-reset btn-gen text-u fw-700 waves-effect waves-light">' + globalLang['ver-mosaico-utilizado'] + '</a>'

        }
      });
    },
    collectionPagination : function (data,pagination,form,resultsList) {
      let radio = 3;
      let numPages = Math.ceil(data.total/data.tpag);

      if(numPages <= 1) return;

      if(typeof pagination.html() === 'undefined') {
        resultsList.after('<ul class="pagination d-flex flex-wrap align-items-center"></ul>');
        pagination = $('ul.pagination');
      }

      if(data.pag > radio + 1) {
        pagination.append(
            `<li${(1 == data.pag) ? ' class="active"' : ''}>
                <a href="#" class="link-pagination" data-pag="1">
                    <span>1</span>
                </a>
             </li>`
        );
      }
      if(data.pag > radio + 2) {
        pagination.append('<li>...</li>');
      }
      for (let n = data.pag - radio; n <= data.pag + radio; n++) {
        if(n <= 0 || n > numPages) continue;
        pagination.append(
            `<li${(n == data.pag) ? ' class="active"' : ''}>
                <a href="#" class="link-pagination" data-pag="${n}">
                    <span>${n}</span>
                </a>
             </li>`
        );
      }
      if(data.pag + radio < numPages - 1) {
        pagination.append('<li>...</li>');
      }
      if(data.pag + radio < numPages) {
        pagination.append(
            `<li${(numPages == data.pag) ? ' class="active"' : ''}>
                <a href="#" class="link-pagination" data-pag="${numPages}">
                    <span>${numPages}</span>
                </a>
             </li>`
        );
      }
      //Activamos links
      $('.link-pagination').off('click').on('click',function (event){
        event.preventDefault();
        form.find('#pag').val($(this).data('pag'));
        app.getMosaics();
      })
    },
    getUrlGet : function (formData,isMosaic,change) {
      var getChain = '';
      $.ajax({
        url : site_url + '/link_mosaics_gallery_getpart',
        method : 'post',
        async: false,
        data : {isMosaic : isMosaic, formData : formData, change : change},
        success : function (response) {
          getChain = response;
        }
      })
      return getChain;
    },
    mosaicBlocks : function () {
      let mosaicBlock = $('.wrapper--mosaic_block');
      let mosaicBlockHeader = mosaicBlock.find('.header');

      mosaicBlockHeader.click(function() {
        $(this).closest('.wrapper--mosaic_block').toggleClass('active');
      })
    },
    packaging : function () {
      let selectPackaging = $('select#packaging');
      let blockBody = selectPackaging.parent().parent().parent();

      if(typeof selectPackaging.html() !== 'undefined') {
        this.packagingShow(selectPackaging,blockBody);
      }

      selectPackaging.change(function (){
        app.packagingShow($(this),blockBody);
      })
    },
    packagingShow : function (selectPackaging,blockBody) {
      let format = selectPackaging.val();
      let mosaic = selectPackaging.data('mosaic');
      blockBody.find('.value').empty();
      $.ajax(
          {
            url : site_url + '/get_packaging',
            dataType : 'json',
            method : 'POST',
            data : {mosaic : mosaic, format : format},
            success : function(data)
            {
              if(!data.noData) {
                for (let d in data) {
                  if(typeof blockBody.find('.value.' + d).html() !== 'undefined') {
                    blockBody.find('.value.' + d).html(data[d]);
                  }
                }
              }
            }
          }
      )
    },
    formBudget : function () {
      let inputBudget = $('.checkbox-presupuesto').find('#presupuesto');
      let fieldsBudget = $('.fields-budget');
      let fieldQuantity = $('#presupuesto_cantidad');
      inputBudget.click(function (){
        if($(this).prop('checked')) {
          fieldsBudget.show();
          fieldQuantity.attr('data-validation','required');
        }
        else {
          fieldsBudget.hide();
          fieldQuantity.removeAttr('data-validation');
        }
      })
    },
    menuMosaicMobile : function () {
      let nav = $('#nav-mosaic-mobile');
      let navSubmenu = nav.children('.submenu');
      let navItems= navSubmenu.find('li:not(.category)');

      //Primero preparamos anchura del boton a anchura del texto mas largp y si tenemos activo ponemos teto del boton acorde
      //let maxWidth = 0;
      let textButton = '';
      navItems.each(function (){
        $(this).find('a').each(function (){
          /*let hiddenElement = $(this).clone().appendTo('body');
          let hiddenWidth = hiddenElement.width();
          hiddenElement.remove();
          if (hiddenWidth > maxWidth) maxWidth = hiddenWidth; */
          if($(this).hasClass('active')) textButton = $(this).html();
        })
      })
      /* if(maxWidth){
        nav.find('.btn-rounded').width(maxWidth);
        navSubmenu.width(maxWidth + 40);
      } */
      if(textButton) nav.find('.btn-rounded').html(textButton + '<span class="arrow"></span>');

      //Funcionamiento de despliegue etc
      nav.find('.btn-rounded').click(function (){
        navSubmenu.slideToggle();
        navSubmenu.find('.close').off('click').on('click',function () {
          navSubmenu.slideToggle();
        })
      })
    },
    menuInstalationsMobile : function () {
      let nav = $('.nav-instalaciones-mobile');
      let caller = nav.find('button');
      let menu = nav.find('.navigation');
      let closer = menu.find('.close');

      caller.click(function (){ menu.slideToggle() })
      closer.click(function (){ menu.slideToggle() })
    },
    likeMosaicGallery : function (objCaller) {
      if(!objCaller.hasClass('yes'))
      {
        $.ajax(
            {
              url : site_url + '/add' + (!objCaller.hasClass('mosaico') ? '_voto' : '_like_mosaico'),
              dataType : 'json',
              method : 'POST',
              data : {reg : objCaller.data('id')},
              success : function(data)
              {
                if(!data.hasError){
                  objCaller.parent().children('span.total').html(data.total);
                  objCaller.addClass('yes');
                }
              }
            }
        )
      } else {
        $.ajax(
            {
              url : site_url + '/del' + (!objCaller.hasClass('mosaico') ? '_voto' : '_like_mosaico'),
              dataType : 'json',
              method : 'POST',
              data : {reg : objCaller.data('id')},
              success : function(data)
              {
                if(!data.hasError){
                  objCaller.parent().children('span.total').html(data.total);
                  objCaller.removeClass('yes');
                  //Si se ha hecho filtrado por mis likes ademas quitamos la imagen del listado
                  if(typeof $('#search-for-my-likes').val() !== 'undefined') {
                    objCaller.closest('li').remove();
                  }
                }
              }
            }
        )
      }
    },
    materialsTables : function () {
      $('.table-collapsed').find('th').click(function (){
        let parent = $(this).closest('.table-collapsed');
        if(parent.hasClass('active')) parent.removeClass('active');
        else parent.addClass('active');
      })
    },
    generatorIntroMobile : function () {
      let intro = $('.generator-intro-mobile');
      let introHeader = intro.find('.header');

      introHeader.click(function() {
        intro.toggleClass('active');
      })
    },
    cookiesAccept : function () {
      $('#cookie-btn').click(function (e){
        let cookiesPopup = $('#cookies-popup');
        e.preventDefault();
        $.cookie(cookieGA, 'Y', {expires : 7, path: '/'});
        $.cookie(cookieYT, 'Y', {expires : 7, path: '/'});
        $.cookie(cookieRS, 'Y', {expires : 7, path: '/'});
        cookiesPopup.hide();
        location.reload();
      })
    },
    cookiesRefuse : function () {
      $('#cookie-btn-refuse').click(function (e){
        let cookiesPopup = $('#cookies-popup');
        e.preventDefault();
        $.cookie(cookieGA, 'N', {expires : 7, path: '/'});
        $.cookie(cookieYT, 'N', {expires : 7, path: '/'});
        $.cookie(cookieRS, 'N', {expires : 7, path: '/'});
        cookiesPopup.hide();
        location.reload();
      })
    },
    cookiesConfigure : function ()
    {
      var configureButton = $('#cookie-btn-configure');
      var configureModal = $('#modal-cookies-popup');
      var closeModalButton = configureModal.find('button.close');
      var finishConfigureButton = configureModal.find('button#configure-cookie-finish');
      var chkAll = configureModal.find('#chk_all');
      var chk = configureModal.find('input[type="checkbox"]:not(#chk_all)');
      chkAll.click(function (){
        chk.prop('checked',$(this).prop('checked'));
      })
      chk.click(function (){
        //Comprobamos si estan todos checkeados para marcar el chkAll
        let allChecked = true;
        chk.each(function (){
          if(!$(this).prop('checked')) allChecked = false;
        })

        chkAll.prop('checked',allChecked);
      })
      configureButton.click(function (e)
      {
        e.preventDefault();
        configureModal.show();
      })
      closeModalButton.click(function ()
      {
        configureModal.hide();
      })
      finishConfigureButton.click(function ()
      {
        //Aunque ya este guardado, por si acaso, guardamos el estado como se queda
        if( $('input#chk_' + cookieGA).prop('checked') ) {
          $.cookie(cookieGA, 'Y', { expires: 7, path: '/' })
        }
        else {
          $.cookie(cookieGA, 'N', { expires: 7, path: '/' })
        }
        if($('input#chk_' + cookieYT).prop('checked')) $.cookie(cookieYT, 'Y', { expires: 7, path: '/' });
        else $.cookie(cookieYT, 'N', { expires: 7, path: '/' });
        if($('input#chk_' + cookieRS).prop('checked')) $.cookie(cookieRS, 'Y', { expires: 7, path: '/' });
        else $.cookie(cookieRS, 'N', { expires: 7, path: '/' });
        $('#cookies-popup').hide();
        //Cerramos el modal
        configureModal.hide();
        location.reload();
      })
    },
    cookiesConfigureLegal : function ()
    {
      var configureModal = $('.legal-cookies');
      var finishConfigureButton = configureModal.find('button#configure-cookie-finish');
      var chkAll = configureModal.find('#chk_all');
      var chk = configureModal.find('input[type="checkbox"]:not(#chk_all)');
      chkAll.click(function (){
        chk.prop('checked',$(this).prop('checked'));
      })
      chk.click(function (){
        //Comprobamos si estan todos checkeados para marcar el chkAll
        let allChecked = true;
        chk.each(function (){
          if(!$(this).prop('checked')) allChecked = false;
        })

        chkAll.prop('checked',allChecked);
      })
      finishConfigureButton.click(function ()
      {
        //Aunque ya este guardado, por si acaso, guardamos el estado como se queda
        if( $('input#chk_' + cookieGA).prop('checked') ) {
          $.cookie(cookieGA, 'Y', { expires: 7, path: '/' })
        }
        else {
          $.cookie(cookieGA, 'N', { expires: 7, path: '/' })
        }
        if($('input#chk_' + cookieYT).prop('checked')) $.cookie(cookieYT, 'Y', { expires: 7, path: '/' });
        else $.cookie(cookieYT, 'N', { expires: 7, path: '/' });
        if($('input#chk_' + cookieRS).prop('checked')) $.cookie(cookieRS, 'Y', { expires: 7, path: '/' });
        else $.cookie(cookieRS, 'N', { expires: 7, path: '/' });
        location.reload();
      })
    },
    cookiesAcceptVideos : function () {
      $('.acceptance-video-cookies').click(function (e){
        e.preventDefault();
        $.cookie(cookieYT, 'Y', {expires : 7, path: '/'});
        location.reload();
      })
    },
    rangeDoubleSlide : function() {
      let doubleSlides = document.querySelectorAll('.filter-range');
      doubleSlides.forEach((el) => {
        let fromSlider = el.querySelector('[id$="-fromSlider"]'),
            toSlider = el.querySelector('[id$="-toSlider"]'),
            fromInput = el.querySelector('[id$="-fromInput"]'),
            toInput = el.querySelector('[id$="-toInput"]'),
            type = (fromSlider.getAttribute('id').replace('-fromSlider','') === 'precio') ? 'float' : 'int';

        fromSlider.oninput = () => app.controlFromSlider(fromSlider, toSlider, fromInput, type);
        toSlider.oninput = () => app.controlToSlider(fromSlider, toSlider, toInput, type);
        fromInput.oninput = () => app.controlFromInput(fromSlider, fromInput, toInput, toSlider, type);
        toInput.oninput = () => app.controlToInput(toSlider, fromInput, toInput, toSlider, type);
      })
    },
    controlFromInput : function (fromSlider, fromInput, toInput, controlSlider, type) {
      const [from, to] = app.getParsed(fromInput, toInput, type);
      if (from > to) {
        fromSlider.value = to;
        fromInput.value = to;
      } else {
        fromSlider.value = from;
      }
    },
    controlToInput : function (fromSlider, fromInput, toInput, controlSlider, type) {
      const [from, to] = app.getParsed(fromInput, toInput, type);
      if (from <= to) {
        toSlider.value = to;
        toInput.value = to;
      } else {
        toInput.value = from;
      }
    },
    controlFromSlider : function (fromSlider, toSlider, fromInput, type) {
      const [from, to] = app.getParsed(fromSlider, toSlider, type);
      if (from > to) {
        fromSlider.value = to;
        fromInput.value = to;
      } else {
        fromInput.value = from;
      }
    },
    controlToSlider : function (fromSlider, toSlider, toInput, type) {
      const [from, to] = app.getParsed(fromSlider, toSlider, type);
      if (from <= to) {
        toSlider.value = to;
        toInput.value = to;
      } else {
        toInput.value = from;
        toSlider.value = from;
      }
    },
    getParsed : function (currentFrom, currentTo, type) {
      const from = (type === 'int') ? parseInt(currentFrom.value) : parseFloat(currentFrom.value);
      const to = (type === 'int') ? parseInt(currentTo.value) : parseFloat(currentTo.value);
      return [from, to];
    }
  }

  // DOM Ready
  $(function() {

    $(".heart").click(function(){
      var objCaller = $(this)
      app.likeMosaicGallery(objCaller);
    })

    $('#btn-consultar-stock').click(function ()
    {
      var formatSelect = $('select#formato');
      var quantityInput = $('input#cantidad');
      var format = formatSelect.val();
      var type = formatSelect.find('option[value="' + format + '"]').data('type')
      var quantity = quantityInput.val().replace(',','.');
      var msg = $('div.resultado-consulta');
      var mosaic = $(this).data('mosaic');

      msg.empty().hide();

      if(type === '' || quantity === '' || type === undefined) stockValidation.show().html(msgStock1)
      else if(isNaN(parseFloat(quantity))) stockValidation.show().html(msgStock2);
      else
      {
        stockValidation.hide()
        $.ajax(
            {
              url : site_url + '/check_stock',
              dataType : 'json',
              method : 'POST',
              data : {type : type, quantity : quantity, mosaic : mosaic, format : format},
              success : function(data)
              {
                msg.html(data.mensaje);
                msg.show();
                ga('send','event','Stock','Consultar',stockNameProduct);
                //Registrmos el click
                $.ajax({
                  url : site_url + '/register_stock_click',
                  method : 'POST',
                  data : {producto : stockNameProduct, formato: formatSelect.find('option[value="' + format + '"]').text(), m2 : quantity },
                })
              }
            }
        )
      }

    })


    $('.header-mobile-menu').find('.close').click(function () {
      $('#mobile-menu').sideNav('hide');
    })

    let heightMobileMenu = $('#mobile-menu').outerHeight();
    let heightMobileFinish = $('.mobile-submenu').position().top + $('.mobile-submenu').outerHeight();

    if(heightMobileFinish < heightMobileMenu) {
      let addHeight = heightMobileMenu - heightMobileFinish;
      $('.mobile-submenu').outerHeight($('.mobile-submenu').outerHeight() + addHeight);
    }


    app.init();
  });
})(jQuery);